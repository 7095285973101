import { useCallback, useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import VerifyLogs from "./components/verifyLogs";
import CustomerOrdersTable from "./components/customerOrdersTable";

const App = () => {
  // const [removeAudioLoading, setRemoveAudioLoading] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [onBoardingButton, setOnBoardingButton] = useState(false);
  const [audioVerificationStatus, setAudioVerificationStatus] = useState(null);
  const [selfieResponseData, setSelfieResponseData] = useState({
    user_id: null,
    verifiation_date_time: "",
    verification_status: null,
  });
  const [customerIsVerifying, setCustomerIsVerifying] = useState(false);
  const [sendAppLinkLoading, setSendAppLinkLoading] = useState(false);
  const [shopifyUserOrders, setShopifyUserOrders] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const customerNumber = queryParameters.get("number");
  const sid = queryParameters.get("sid");
  console.log("sid =>", sid);

  const baseUrl = 'dev-api.verifinow.io';
  // const baseUrl = "staging-api.verifinow.io";

  //DEV EMPLOYEE TOKEN
  const token = "MTg2Mg.yh8ylj6yTYRvokS3BhrH7IpXjpicmUUrP-EOSskSbJJYnCtvWc4H-h3xNBuD";

  // const token =
  //   "OTU3.ZO9OUmgJPjyqikJ8tEQWjgjxwzEPU5z0zVkztjELVtM24_ryj2KvqyidrdHi";

  const handleVerifyBySelfie = async () => {
    try {
      setCustomerIsVerifying(true);
      const verifySelfie = await fetch(
        `https://${baseUrl}/pushnotification/${userDetail?.id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          method: "POST",
        }
      );
      const verifySelfieResponse = verifySelfie.data;
      if (!verifySelfieResponse.status) {
        setCustomerIsVerifying(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCustomerIsVerifying(false);
    }
  };

  const getCustomerDetailsFromShopify = async (number) => {
    try {
      const getShopifyCustomer = await fetch(
        `https://${baseUrl}/shopify/customerDetail`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ phone: number, twilio_sid: sid }),
        }
      );
      const resp = await getShopifyCustomer.json();
      if (resp?.data?.length) {
        setShopifyUserOrders(resp.data);
      } else {
        toast.error("This customer does not exist in the shopify store", {
          theme: "colored",
          autoClose: false,
        });
      }
    } catch (error) {
      console.log("Error =>", error);
    }
  };

  const getCustomerDetails = async (number) => {
    try {
      const response = await fetch(
        `https://${baseUrl}/agent/customers/search`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ searchkey: number }),
        }
      );
      const resp = await response.json();
      if (resp?.data) {
        setUserDetail(resp?.data[0]);
      } else {
        toast.error("This customer does not exist in our system.", {
          theme: "colored",
          autoClose: false,
        });
        setOnBoardingButton(true);
      }
    } catch (error) {
      console.log("error =>");
    }
  };

  // const removeAudioOnBoarding = async (number) => {
  //   try {
  //     setRemoveAudioLoading(true);
  //     const response = await fetch(
  //       `https://${baseUrl}//agent/customers/removeOnboarding`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //         body: JSON.stringify({ mobile_phone: number }),
  //       }
  //     );
  //     const resp = await response.json();
  //     if (resp?.data) {
  //       setAudioVerificationStatus(null);
  //     }
  //   } catch (error) {
  //     console.log("error =>");
  //   } finally {
  //     setRemoveAudioLoading(false);
  //   }
  // };

  const sendApplicationLink = async () => {
    try {
      setSendAppLinkLoading(true);
      const response = await fetch(`https://${baseUrl}/generateAppLink`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ mobile_phone: customerNumber.trim() }),
      });
      const resp = await response.json();
      if (resp?.data) {
        toast.success(resp.message, {
          theme: "colored",
          autoClose: false,
        });
      } else {
        toast.error(resp.message, {
          theme: "colored",
          autoClose: false,
        });
      }
    } catch (error) {
      console.log("error =>", error);
    } finally {
      setSendAppLinkLoading(false);
    }
  };

  const socketConnection = useCallback(() => {
    const stagingSocketUrl = `wss://${baseUrl}`;
    var ws = new WebSocket(stagingSocketUrl);

    ws.onopen = () => {
      console.log("Connected to the server");
    };
    ws.onerror = (e) => {
      console.log("OnError =>", e);
      ws.close();
    };

    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log("data =>", data);
      if (data.data?.user_id === userDetail?.id) {
        if (data?.type === "selfie_veification") {
          setSelfieResponseData({
            user_id: data?.data?.user_id,
            verifiation_date_time: data?.data?.verifiation_date_time,
            verification_status: data?.data?.verification_status,
          });
        }
      }
    };

    ws.onclose = (e) => {
      console.log("Disconnected. Check internet or server.", e);
      socketConnection();
    };
  }, [userDetail]);

  useEffect(() => {
    if (userDetail?.verification_logs?.length) {
      userDetail.verification_logs.filter((element, index) => {
        if (element.type === "AUDIO_VERIFICATION") {
          if (index === 0) {
            setAudioVerificationStatus(element);
          }
        }
      });
    }
  }, [userDetail]);

  useEffect(() => {
    if (customerNumber) {
      getCustomerDetails(customerNumber.trim());
      getCustomerDetailsFromShopify(customerNumber.trim());
    }
    // getCustomerDetails("+923324910597");
  }, [customerNumber, selfieResponseData]);

  // useEffect(() => {
  //   (async () => {
  //     await getCustomerDetailsFromShopify("+923324910597");
  //   })();
  // }, [customerNumber]);

  useEffect(() => {
    socketConnection();
  }, [socketConnection]);

  return (
    <div>
      <ToastContainer />
      <Grid item>
        <img
          src={require("./../src/assets/images/logo.png")}
          style={{
            width: 160,
            marginLeft: 15,
            marginTop: 10,
            borderRadius: 10,
          }}
          alt="logo"
        />
      </Grid>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          item
          xs={11}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", marginTop: 5 }}
          >
            <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
              Customer Detail
            </Typography>
            &nbsp;&nbsp;
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ pt: 1, mt: 2 }}
          >
            <Grid>
              <img
                src={
                  userDetail?.selfie_url ??
                  require("./../src/assets/images/avatar.jpeg")
                }
                style={{ width: 150, height: 150, borderRadius: 10 }}
                alt={userDetail?.company?.name}
              />
            </Grid>

            <Grid>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                sx={{ padding: 2 }}
              >
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    Name:
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography>{userDetail?.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    Mobile:
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography>{userDetail?.mobile_phone}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    Email:
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography>{userDetail?.email}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    Address:
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography>{userDetail?.address}</Typography>
                </Grid>
                {audioVerificationStatus ? (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                      Voice Verification:
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography
                      color={audioVerificationStatus?.status ? "green" : "red"}
                      variant="body1"
                    >
                      <Grid container>
                        {audioVerificationStatus?.status
                          ? "Verified"
                          : "Not Verified"}
                      </Grid>
                    </Typography>
                  </Grid>
                ) : null}
                {selfieResponseData?.verification_status === false ? (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                      Selfie Verification:
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography color={"red"} variant="body1">
                      <Grid container>Not Verified</Grid>
                    </Typography>
                  </Grid>
                ) : null}
                {selfieResponseData?.verification_status === true ? (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                      Selfie Verification:
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography color={"green"} variant="body1">
                      <Grid container>Verified</Grid>
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12} ml={2}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  {onBoardingButton ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                        OnBoarding link:
                      </Typography>
                      {sendAppLinkLoading ? (
                        <Typography
                          variant="body2"
                          fontSize={16}
                          color="red"
                          sx={{ marginLeft: 3 }}
                        >
                          Sending....
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          sx={{
                            textTransform: "none",
                            marginLeft: 2,
                            width: 115,
                          }}
                          onClick={() => sendApplicationLink()}
                        >
                          Send link
                        </Button>
                      )}
                    </Grid>
                  ) : null}
                  {/* {audioVerificationStatus &&
                  !audioVerificationStatus?.status ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                        Voice verification:
                      </Typography>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          textTransform: "none",
                          marginLeft: 1.2,
                          width: 110,
                        }}
                        onClick={() => removeAudioOnBoarding(customerNumber.trim())}
                      >
                        Verify audio
                      </Button>
                    </Grid>
                  ) : null} */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      disabled={userDetail?.is_incode_onboading_complete ? false : true}
                      sx={{
                        textTransform: "none",
                      }}
                      onClick={() => handleVerifyBySelfie()}
                    >
                      {customerIsVerifying
                        ? "Verifying..."
                        : "Verify by Selfie"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: 10,
              marginBottom: 15,
              flexGrow: 1,
            }}
          >
            <Typography
              style={{ fontSize: 20, fontWeight: "bold", marginBottom: 15 }}
            >
              Verification Logs
            </Typography>
            <VerifyLogs customerLogs={userDetail?.verification_logs ?? []} />
          </div>
          <Grid container sx={{ mb: 10, mt: 5 }}>
            <Typography
              style={{ fontSize: 20, fontWeight: "bold", marginBottom: 15 }}
            >
              Shopify Order Details
            </Typography>
            <CustomerOrdersTable orders={shopifyUserOrders ?? []} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
